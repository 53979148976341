@use "@angular/material" as mat;
@use "@anvilor/ngx-theming" as nvt;
@use "themes";

@mixin button-root-styles() {
    .theme-button {
        @include nvt.theme-it(themes.$themes, custom, border-radius, border-radius, $root: true);
        width: fit-content;
        min-width: max-content;
        max-width: 100%;
        height: fit-content;
        padding: 8px 24px;
        box-sizing: border-box;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        cursor: pointer !important;

        font-family: Inter, sans-serif;
        font-weight: 500;

        @media (max-width: 576px) {
            font-size: 14px;
        }

        @media (min-width: 577px) {
            font-size: 16px;
        }

        mat-icon {
            min-width: fit-content;
        }
    }


    .primary-button {
        @include nvt.theme-it(themes.$themes, primary, base, border-color, $root: true);

        &:hover {
            border-color: #ffffff00;
        }

        &:disabled {
            @include nvt.theme-it(themes.$themes, foreground, disabled, background-color, $root: true);
            @include nvt.theme-it(themes.$themes, foreground, disabled, border-color, $root: true);
            cursor: not-allowed;
        }
    }

    .alt-button {
        @include nvt.theme-it(themes.$themes, accent, base, border-color, $root: true);

        &:hover {
            border-color: #ffffff00;
        }

        &:disabled {
            @include nvt.theme-it(themes.$themes, foreground, disabled, background-color, $root: true);
            @include nvt.theme-it(themes.$themes, foreground, disabled, border-color, $root: true);
            cursor: not-allowed;
        }
    }

    .hidden-button {
        background-color: transparent;
        border-color: #00000000;

        &:hover {
            @include nvt.theme-it(themes.$themes, foreground, divider, border-color, $root: true);
        }
    }

    .outlined-button {
        background-color: transparent;
        @include nvt.theme-it(themes.$themes, custom, border-radius, border-radius, $root: true);
        @include nvt.theme-it(themes.$themes, primary, base, border-color, $root: true);

        &:hover {
            border-color: #ffffff00;
            @include nvt.colored-background(themes.$themes, primary, base, $root: true);
        }
    }

    .survey-response-button,
    .attachment-button {
        padding: 4px 16px;
        @include nvt.theme-it(themes.$themes, custom, border-radius, border-radius, $root: true);
        @include nvt.theme-it(themes.$themes, primary, base, border-color, $root: true);

        &:hover {
            @include nvt.theme-it(themes.$themes, primary, base, border-color, $root: true);
        }
    }

    .border-button {
        background-color: transparent;
        @include nvt.theme-it(themes.$themes, custom, border-radius, border-radius, $root: true);
        @include nvt.theme-it(themes.$themes, foreground, divider, border-color, $root: true);

        &:hover {
            @include nvt.theme-it(themes.$themes, primary, base, border-color, $root: true);
        }
    }

    // If you put this back make sure it doesn't apply inside HFE Editor!!!!
    // button:disabled {
    //     // color: #c1c1c1 !important;

    //     background-color: rgb(227, 227, 227) !important;
    //     cursor: not-allowed !important;
    //     border-color: rgb(227, 227, 227) !important;

    //     &:before,
    //     &:hover:before {
    //         content: "";
    //         opacity: 0 !important;
    //     }
    // }

}