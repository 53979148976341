@media (max-width: 767px) {

    .html-ffe-editor-top-level-control .label-field,
    .html-ffe-editor-top-level-control di-label-field,
    .html-ffe-editor-label {
        font-size: 15px;
    }

    .input-xs {
        height: 24px;
        font-size: 15px;
    }

    .state-button {
        min-width: 25px !important;
        height: 24px !important;
        font-size: 15px;
    }

    .state-button>.glyphicon {
        font-size: 16px;
    }

    .html-ffe-editor-top-level-control .btn-xs,
    .html-ffe-editor-top-level-control .btn-group-xs>.btn {
        min-height: 24px;
        font-size: 15px;
    }

    .di-dropdown-field-button {
        min-height: 24px;
    }

    .dropdown-caret {
        top: 9px;
    }

    .mat-mdc-menu-content .mat-mdc-menu-item span {
        font-size: 17px !important;
    }

    .di-time-form .time-input-control-24-hour {
        width: 50px;
    }

    .checkbox-div>label>input {
        margin-top: 2px !important;
    }
}