@use "@anvilor/ngx-theming"as nvt;
@use 'themes';


@mixin utility-root-styles() {

  .border-base {
    border-style: solid !important;
    border-width: 2px !important;

    &-color {
      @include nvt.theme-it(themes.$themes, foreground, divider, border-color, $root: true);
    }
  }



  // Interactive styles
  .transition-base {
    transition: all 0.166s cubic-bezier(.15, .58, .43, .96) !important;
    -webkit-transition: all .166s cubic-bezier(.15, .58, .43, .96) !important;
    -moz-transition: all .166s cubic-bezier(.15, .58, .43, .96) !important;
  }

  .shadow-base {
    box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.04);
  }

  .interactive-base {
    overflow: hidden;
    position: relative;



    &:after {
      transition: all 0.166s cubic-bezier(.15, .58, .43, .96) !important;
      -webkit-transition: all .166s cubic-bezier(.15, .58, .43, .96) !important;
      -moz-transition: all .166s cubic-bezier(.15, .58, .43, .96) !important;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      position: absolute;
      width: 125%;
      height: 125%;
      background: #000;
      opacity: 0.0 !important;
    }

    &:hover:after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      position: absolute;
      width: 125%;
      height: 125%;
      background: #000;
      opacity: 0.066 !important;
      cursor: pointer;
    }
  }

  .interactive-color {
    overflow: hidden;
    position: relative;


    &:after {
      transition: all 0.166s cubic-bezier(.15, .58, .43, .96) !important;
      -webkit-transition: all .166s cubic-bezier(.15, .58, .43, .96) !important;
      -moz-transition: all .166s cubic-bezier(.15, .58, .43, .96) !important;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      position: absolute;
      width: 125%;
      height: 125%;
      @include nvt.theme-it(themes.$themes, primary, base, background-color, $root: true);
      opacity: 0.0 !important;
      cursor: default;
    }

    &:hover:after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      position: absolute;
      width: 125%;
      height: 125%;
      @include nvt.theme-it(themes.$themes, primary, base, background-color, $root: true);
      opacity: 0.15 !important;
      cursor: default;
    }
  }

}
