@use "@anvilor/ngx-theming" as nvt;
@use 'themes';
@use 'sass:map';

// Force hidden style
[hidden] {
    display: none !important;
}

// root sizing references
html {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Inter', sans-serif;
}

mat-radio-button {
    margin-right: 10px;
}

mat-icon {
    width: fit-content !important;
    height: fit-content !important;
    margin: 0px;
}

mat-snack-bar-container {
    .mdc-snackbar__surface {
        // @include nvt.theme-it(themes.$themes, foreground, primary, color, $root: true);
        @include nvt.theme-it(themes.$themes, background, surface, background-color, $root: true);
    }
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
    min-width: initial !important;
    max-width: calc(100vw - 40px) !important;
}

mat-dialog-container {
    zs-hfe-portal-editor {
        padding: 10px;
    }
}

.toolbar-main-div {

    .header-toolbar-row,
    mat-toolbar-row {
        @include nvt.theme-it(themes.$themes, foreground, divider, border-color, $root: true);
        min-height: none !important;
    }

    .header-toolbar-row {
        @include nvt.theme-it(themes.$themes, background, surface, background-color, $root: true);
        @include nvt.theme-it(themes.$themes, foreground, divider, color, $root: true);

        .community-name-div {

            @include nvt.theme-it(themes.$themes, foreground, primary, color, $root: true);
            @include nvt.theme-it(themes.$themes, custom, border-radius, border-radius, $root: true);
        }

        .toolbar-primary-logo-container {
            @include nvt.theme-it(themes.$themes, custom, border-radius, border-radius, $root: true);
        }

        .toolbar-menu-wrapper {
            @include nvt.theme-it(themes.$themes, foreground, primary, color, $root: true);
        }
    }
}

.mat-toolbar-multiple-rows {
    min-height: none !important;
}

// TODO

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing,
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing,
.mat-drawer-backdrop.mat-drawer-shown {
    opacity: 0.2 !important;
    visibility: visible;
    background-color: #000;
}

.fullscreen-mat-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100%;
    height: 100%;
}

:root {
    --sidenav-width: 300px;
}

.mat-mdc-text-field-wrapper {
    background-color: #deebf7AA;
}