@use "@angular/material" as mat;
@use "@anvilor/ngx-theming" as nvt;
@use 'themes';

@use './style-components/_buttons.scss' as button-styles;
@use './style-components/_text-styles.scss' as font-styles;
@use './style-components/_utility-styles.scss' as utility-styles;

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './main-app.scss';

// @import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300&family=Inter:wght@200;300;400;500;600;700;900&display=swap');
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import '@angular/cdk/overlay-prebuilt.css';

@import "jsoneditor/dist/jsoneditor.min.css";

@include mat.core();
@include nvt.setup((components: "all", themes: themes.$themes));

@include button-styles.button-root-styles();
@include font-styles.font-root-styles();
@include utility-styles.utility-root-styles();