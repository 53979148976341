@use "@anvilor/ngx-theming"as nvt;
@use 'themes';

@mixin font-root-styles() {

    html {
        @media (max-width: 576px) {
            font-size: 14px;
        }

        @media (min-width: 577px) {
            font-size: 16px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        margin: 0;
        color: #000;
    }

    h1 {
        font-family: Inter Tight, sans-serif;
        font-weight: 600;
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    h2 {
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    h3 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 1.75rem;
        margin-bottom: .75rem;
    }

    h4 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 1.25rem;
        margin: 0.5rem 0rem;
    }

    .mat-header-cell {
        font-family: Inter Tight, sans-serif;
        font-weight: 600;
        margin-bottom: 4px;
        font-size: 14px;
    }

    mat-error {
        word-break: break-word;
    }

    .mat-label,
    mat-label,
    .mat-form-label {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 16px;
    }

    .mat-chip-label {
        font-family: Inter Tight, sans-serif;
        line-height: 100%;
        font-weight: 400;

        @media (max-width: 576px) {
            font-size: 14px;
        }

        @media (min-width: 577px) {
            font-size: 16px;
        }
    }

    .NgxEditor__Placeholder p,
    .NgxEditor__Content p {
        // The following styles are to match the margin styles in HFE and should not be changed
        margin-block-end: 0.5em;
        margin-block-start: 0.5em;
        margin: revert !important;
    }

    p,
    .mat-cell,
    .mat-input-label,
    .submitted-link-name-span td span {
        font-family: Inter, sans-serif;
        font-weight: 400;

        @media (max-width: 576px) {
            font-size: 14px;
        }

        @media (min-width: 577px) {
            font-size: 16px;
        }

        mat-icon {
            font-size: 24px;
        }
    }

    th {
        font-family: Inter, sans-serif;
        font-weight: 600;

        padding: 0px 8px 0px 8px !important;

        @media (max-width: 576px) {
            font-size: 12px;
        }

        @media (min-width: 577px) {
            font-size: 13px;
        }
    }

    .submitted-link-name-span,
    .attachment-name {
        font-weight: 400;
    }

    .submitted-at-span,
    mat-hint {
        font-family: Inter Tight, sans-serif;
        font-size: 14px !important;
        font-weight: 300;
        color: #000;
    }

    .footnote {
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #000;
        font-weight: 400;
        margin-top: 2px;
        font-size: 14px;

        &-light,
        mat-hint {
            opacity: 0.50;
            font-size: 14px;
            font-family: Inter, sans-serif;
            font-weight: 400;
            margin-top: 2px;
        }
    }

    .timer .label-span {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        margin: 0px 5px 0px 5px;
    }

    .icon-filter-field {
        font-size: 16px;
        padding: 0px 16px;
        min-width: none;
        box-sizing: border-box;
    }

    .toolbar-label {
        font-size: 18px;
        font-family: Inter, sans-serif;
        color: #000;
        font-weight: 500;

        width: max-content;
        margin-left: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
}
